<template>
  <div id="app">
    <Nav />
    <router-view :key="$route.fullPath" />
    <Floor />
    <GoTop />
  </div>
</template>
<script>
import Nav from '@/components/nav';
import Floor from '@/components/floor'
import GoTop from '@/components/goTop'
export default {
  name: 'App',
  components: {
    Nav,
    Floor,
    GoTop
  },
}
</script>

<style lang="less">
@import './assets/css/com.less';
@import './assets/css/help.less';
</style>
