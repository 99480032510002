import { createRouter, createWebHashHistory } from 'vue-router'
const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: '首页',
    component: () => import('@/views/home/index.vue')
  },
  {
    path: '/energy',
    name: '能源板块',
    redirect: '/energy/supplyChain',
    children: [
      {
        path: '/energy/supplyChain',
        name: '供应链平台',
        component: () => import('@/views/energy/supplyChain/index.vue'),
      },
      {
        path: '/energy/digitization',
        name: '数字化平台',
        component: () => import('@/views/energy/digitization/index.vue'),
      },
    ]
  },
  // {
  //   path: '/equipment',
  //   name: '装备板块',
  //   component: () => import('@/views/equipment/index.vue')
  // },
  // {
  //   path: '/materials',
  //   name: '材料板块',
  //   component: () => import('@/views/materials/index.vue')
  // },
  {
    path: '/journalism',
    name: '新闻动态',
    redirect: '/journalism/journalism',
    children: [
      {
        path: '/journalism/dynamic',
        name: '理业动态',
        component: () => import('@/views/journalism/index.vue'),
      },
      {
        path: '/journalism/journalism',
        name: '行业新闻',
        component: () => import('@/views/journalism/index.vue'),
      },
      {
        path: '/journalism/study',
        name: '研究报告',
        component: () => import('@/views/journalism/index.vue'),
      },
      {
        path: '/journalism/articleDes/:id',
        component: () => import('@/views/journalism/articleDes/index.vue'),
      },
    ]
  },
  {
    path: '/joinUs',
    name: '加入我们',
    redirect: '/joinUs/joinUs',
    children: [
      {
        path: '/joinUs/joinUs',
        name: '招纳贤士',
        component: () => import('@/views/joinUs/society/index.vue'),
      },
      {
        path: '/joinUs/school',
        name: '校园专场',
        component: () => import('@/views/joinUs/school/index.vue'),
      },
      {
        path: '/joinUs/joinUsDes/:id',
        component: () => import('@/views/joinUs/joinUsDes/index.vue'),
      },
    ]
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
