<template>
  <div v-if="scrollTop>300" @click="goTop" class="goTop">
  </div>
</template>
<script>
export default {
  data() {
    return {
      scrollTop: 0,
    }
  },
  mounted() {
    window.addEventListener('scroll', () => {
      this.scrollTop = window.scrollY;
    })
  },
  methods: {
    goTop() {
      window.scrollTo({
        'top': 0,
        'behavior': 'smooth'
      })
    }
  }
}
</script>
<style lang="less" scoped>
.goTop {
  width: 60px;
  height: 60px;
  position: fixed;
  right: 40px;
  bottom: 40px;
  cursor: pointer;
  background: url(@/assets/image/77.png);
}
.goTop:hover {
  background: url(@/assets/image/78.png);
}
</style>