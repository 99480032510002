<template>
  <div class="floor">
    <div class="type_area">
      <div class="content">
        <div v-for="(item, index) in floor_list" :key="index" class="list">
          <div class="th">{{item.th}}</div>
          <div @click="toskip(v.a)" v-for="(v, i) in item.td" :key="i" :class="v.a ? 'td click' : 'td'">{{v.name}}</div>
        </div>
        <div class="ercode">
          <img src="@/assets/image/ercode.png" alt="">
          <span>关注理业公众号</span>
        </div>
      </div>
      <div class="remak">Copyright © 2002-2025 理业投资控股集团<a href="https://beian.miit.gov.cn/">沪ICP备2021008492号</a></div>
    </div>
  </div>
</template>
<script>
import {floor_list} from './dataOptions'
export default {
  data() {
    return {
      floor_list: floor_list(),
    }
  },
  methods: {
    toskip(a) {
      if(a) this.$router.push(a)
    }
  }
}
</script>
<style lang="less" scoped>
.floor {
  min-width: 1180px;
  height: 280px;
  background: #0B3A7C;
  color: #fff;
  .content {
    overflow: hidden;
    .list {
      float: left;
      margin-right: 120px;
      .th {
        width: 80px;
        line-height: 28px;
        font-size: 20px;
        padding-top: 43px;
        padding-bottom: 18px;
      }
      .td {
        line-height: 32px;
      }
    }
    .ercode {
      float: right;
      width: 106px;
      margin-top: 43px;
      font-size: 14px;
      text-align: center;
      >img {
        display: block;
        width: 106px;
        height: 106px;
        margin-bottom: 12px;
      }
    }
  }
  .remak {
    text-align: center;
    margin-top: 46px;
    a {
      margin-left: 24px;
      color: #fff;
    }
    a:hover {
      color: rgba(255,255,255,0.8);
    }
  }
}
</style>