//  列表列配置项getColumnsOptions
export const floor_list = () => {
  return [
    // {
    //   th: "产业布局",
    //   td: [
    //     {
    //       name: '能源板块',
    //       a: '/energy'
    //     },
    //     {
    //       name: '装备板块',
    //       a: '/equipment'
    //     },
    //     {
    //       name: '材料板块',
    //       a: '/materials'
    //     }
    //   ]
    // },
    {
      th: "加入我们",
      td: [
      {
        name: '招贤纳士',
        a: '/joinUs/joinUs'
      },
      {
        name: '校园专场',
        a: '/joinUs/school'
      },
      ]
    },
    {
      th: "联系我们",
      td: [
      {
        name: '公司电话：+86-21-62150650',
      },
      {
        name: '公司地址：上海市青浦区徐泾镇徐民路168弄中建企业天地东区5号楼',
      }
      ]
    },
  ]
}