<template>
  <div :class="headClass ? 'head head_true':'head'">
    <div class="type_area">
      <h3 @click="$router.push('/home')" class="logo"></h3>
      <div class="nav">
        <div 
          :class="$route.path.indexOf(item.path) !== -1 ? 'check' : ''"
          v-for="(item, index) in nav"
          @mouseenter="mouseover(item, true)"
          @mouseleave="mouseover(item, false)" 
          @click.self="$router.push(item.path)"
        >
          <span @click.self="$router.push(item.path)">{{item.name}}</span>
          <div v-if="item.flag" class="navSon">
            <span 
              v-for="(v, i) in item.children"
              :style="{display: v.name ? 'block':'none'}"
              :key="i"
              :class="$route.path.indexOf(v.path)!==-1?'check':''"
              @click.self="$router.push(v.path)"
            >{{v.name}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import router from '@/router'
export default {
  data() {
    return {
      headClass: false,
      nav: router.options.routes,
		  scrollTop: 0 ,// 记录当前的滚动距离
    }
  },
  created() {
    if(this.$route.path.includes('journalism') || this.$route.path.includes('joinUsDes')) this.headClass = true
  },
  methods: {
    mouseover(props, flag) {
      if(props.flag == flag) return
      let data = JSON.parse(JSON.stringify(this.nav))
      data.map(item => {
        if(item.path == props.path) item.flag = flag
      })
      this.nav = data
    },
  },
  mounted() {
    window.addEventListener('scroll', () => {
      this.scrollTop = window.scrollY;
    })
  },
  watch: {
    scrollTop(newVal) {
      if(this.$route.path.includes('journalism') || this.$route.path.includes('joinUsDes')) return
      this.headClass = newVal > 0 ? true : false
    },
    $route: {
      handler: function(val, oldVal){
        this.nav = JSON.parse(JSON.stringify(router.options.routes))
        this.headClass = (this.$route.path.includes('journalism') || this.$route.path.includes('joinUsDes')) ? true : false
        window.scrollTo({
          'top': 0,
        })
      },
      // 深度观察监听
      deep: true
    }
  }
}
</script>
<style lang="less" scoped>
.head {
  position: fixed;
  height: 88px;
  width: 100%;
  min-width: 1180px;
  z-index: 999;
  .logo {
    width: 180px;
    height: 40px;
    float: left;
    margin: 24px 0;
    margin-right: 50px;
    margin-bottom: 0;
    background: url('@/assets/image/logo1.png') no-repeat no-repeat;
    background-size: 100% 100%;
    cursor: pointer;
  }
  .nav {
    float: left;
    display: inline-block;
    // overflow: hidden;
    >div {
      float: left;
      padding: 0 30px;
      cursor: pointer;
      color: #fff;
      font-size: 16px;
      position: relative;
      >span {
        display: inline-block;
        line-height: 84px;
        border-bottom: 4px solid transparent;
      }
    }
    .check {
      >span {
        border-bottom: 4px solid #fff;
      }
    }
    .navSon {
      box-shadow: 4px 4px 10px 2px rgba(0, 0, 0, 0.3);
      position: absolute;
      top: 88px;
      left: 0;
      z-index: 999;
      span {
        background: #FFFFFF !important;
        color: #C69508 !important;
        display: block;
        width: 124px;
        line-height: 42px;
        text-align: center;
      }
      span:hover, .check {
        background: #C69508 !important;
        color: #fff !important;
      }
    }
  }
}
.head:hover, .head_true {
  background: #fff;
  .logo {
    background: url('@/assets/image/logo.png') no-repeat no-repeat;
    background-size: 100% 100%;
  }
  .nav {
     >div {
      color: #333;
     }
    >div:hover, .check {
      color: #C69508;
      >span {
        border-color: #C69508;
      }
    }
  }
  
}

</style>